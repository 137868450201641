import { AddIcon, CheckIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  Avatar,
  AvatarProps,
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { GoOrganization } from "react-icons/go";
import { Link, useParams } from "react-router-dom";
import { Org } from "~/utils";
import { clientApi } from "~/utils/http";

export default function UserMenuButton({
  size,
  avatar,
  email,
  name,
  organizations,
  ...props
}: Omit<AvatarProps, "avatar"> & {
  avatar?: string;
  name: string;
  email: string;
  organizations: Org[];
}) {
  const params = useParams();
  let items: ContextMenuItemProps[] = [
    {
      id: "",
      to: "/",
      name: name,
      sub: email,
      avatar: avatar,
    },
  ];
  items = items.concat(
    organizations.map((org) => ({
      id: org.organizationId,
      to: "/" + org.organizationId + "/",
      name: org.name,
      sub: org.verifyedName,
      avatar: org.avatar,
    }))
  );
  let currentItem = items[0];
  const { orgId } = params;
  if (orgId) {
    let found = items.find((item) => item.id === orgId);
    if (found) {
      currentItem = found;
    }
  }
  return (
    <Menu>
      <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant={"ghost"}>
        <HStack spacing={0} justifyContent={"end"}>
          <Avatar
            key={currentItem.id}
            size={"sm"}
            name={currentItem.name}
            src={currentItem.avatar}
          />
          <VStack alignItems={"start"} spacing={0} ml={2} fontSize={"sm"}>
            <Text
              textOverflow={"ellipsis"}
              overflow={"hidden"}
              maxW={48}
              fontWeight={"bold"}
            >
              {currentItem.name}
            </Text>
            <Text
              textOverflow={"ellipsis"}
              overflow={"hidden"}
              maxW={48}
              fontWeight={"normal"}
            >
              {currentItem.sub}
            </Text>
          </VStack>
        </HStack>
      </MenuButton>
      <MenuList>
        <MenuGroup title="Switch dashboard context">
          {items.map((item) => (
            <ContextMenuItem
              key={item.id}
              to={item.to}
              name={item.name}
              avatar={item.avatar}
              selected={item === currentItem}
            />
          ))}
        </MenuGroup>
        <MenuDivider />
        <MenuItem as={Link} to="/organizations">
          <Icon as={GoOrganization} mr={4} /> Manage organization
        </MenuItem>
        <MenuItem as={Link} to="/organizations/create">
          <AddIcon mr={4} /> Create organization
        </MenuItem>
        <MenuDivider />
        <MenuItem
          onClick={async () => {
            await clientApi.post("/users/logout");
            sessionStorage.removeItem("user-data");
            window.location.reload();
          }}
        >
          Sign out
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

interface ContextMenuItemProps {
  id?: string;
  name: string;
  avatar?: string;
  sub?: string;
  to: string;
}

function ContextMenuItem({
  name,
  avatar,
  sub,
  to,
  selected,
}: ContextMenuItemProps & {
  selected: boolean;
}) {
  return (
    <MenuItem py={0.5} as={Link} to={to}>
      <CheckIcon mr={4} visibility={selected ? "initial" : "hidden"} />
      <Avatar size={"sm"} name={name} src={avatar} />
      <VStack
        h={"42px"}
        alignItems={"start"}
        spacing={0}
        ml={2}
        fontSize={"sm"}
        justifyContent={"center"}
      >
        <Text fontWeight={"bold"}>{name}</Text>
        <Text fontWeight={"normal"}>{sub}</Text>
      </VStack>
    </MenuItem>
  );
}
