import { Box, Flex, Grid, Heading, Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import Footer from "~/ui/Layout/Footer";

export default function Layout() {
  return (
    <Grid templateRows="136px 1fr 73px" minH="100vh">
      <Header />
      <Outlet />
      <Footer />
    </Grid>
  );
}

function Header() {
  return (
    <Box textAlign={"center"}>
      <Flex
        mt={8}
        mx={"auto"}
        w="100px"
        as={Link}
        to="/"
        justifyContent={"center"}
        flexDir={"row"}
        alignItems={"center"}
      >
        <Image src={process.env.PUBLIC_URL + "/logo192.png"} height="64px" />
        <Heading size="xl" pl="2">
          Sath
        </Heading>
      </Flex>
    </Box>
  );
}
