import {
  Box,
  Button,
  Checkbox,
  Container,
  Heading,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "react-router-dom";
import { z } from "zod";
import FormInput from "~/ui/Form/FormInput";
import { Org, forceReloadUser } from "~/utils";
import { devApi } from "~/utils/http";

const schema = z.object({
  name: z
    .string()
    .min(1, { message: "Please input organization name" })
    .max(32, { message: "Organization name should be no longer than 32" }),
  description: z
    .string()
    .max(1000, { message: "Description should be no longer than 1000" })
    .optional(),
});

export function CreateOrganization() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });
  const navigage = useNavigate();
  return (
    <Box textAlign={"center"} pt={12}>
      <Container>
        <Text color="gray.400">Tell us about your organization</Text>
        <Heading>Set up your organization</Heading>
        <Box
          as={Form}
          mt={12}
          onSubmit={handleSubmit(async (data) => {
            try {
              let res = await devApi.post<{ organization: Org }>(
                "/organizations",
                {
                  name: data.name,
                  description: data.description,
                }
              );
              let org = res.data.organization;
              await forceReloadUser();
              navigage(`/${org.organizationId}`);
            } catch {
              // TODO
            }
          })}
        >
          <Stack spacing={4}>
            <FormInput
              isRequired
              label="Organization name"
              {...register("name")}
              error={errors.name?.message}
            />
            <FormInput
              rows={5}
              label="Description"
              {...register("description")}
              as={Textarea}
              error={errors.description?.message}
            />
            <Button
              isLoading={isSubmitting}
              type="submit"
              mt={4}
              bg={"blue.600"}
            >
              Create
            </Button>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}
