import { CopyIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Container,
  Divider,
  Flex,
  Heading,
  IconButton,
  Spinner,
  Text,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
  Thead,
  Th,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "~/ui/Pagination";
import { useSuccessToast } from "~/utils";
import { devApi } from "~/utils/http";
import { usePaginate } from "~/utils/paginate";

export interface Column {
  name: string;
  type: string;
  expr: string;
}

export interface Dataset {
  datasetId: string;
  name: string;
  description?: string;
  primaryKey: string;
  public: boolean;
  userId: string;
  organaztionId: string | null;
  refId: string | null;
  projectId: string | null;
  createdAt: number;
  numItems: number;
  columns: Column[];
}

export type DatasetItem = {
  [key: string]: string | number | string[] | number[];
};

function ItemTable({
  dataset,
  items,
}: {
  dataset: Dataset;
  items: DatasetItem[];
}) {
  console.log(dataset);

  return (
    <TableContainer>
      <Table size="sm" variant="simple">
        <Thead>
          <Tr>
            <Th>{dataset.primaryKey}</Th>
            {dataset.columns.map((col) => (
              <Th key={col.name}>{col.name}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {items.map((item, i) => (
            <Tr key={i}>
              <Td>{item[dataset.primaryKey]}</Td>
              {dataset.columns.map((col) => (
                <Td key={col.name}>{JSON.stringify(item[col.name])}</Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export default function Dataset() {
  const params = useParams();
  const { orgId, datasetId } = params;
  const [dataset, setDataset] = useState<Dataset | null>(null);
  const toast = useSuccessToast();
  const location = useLocation();

  const loader = async function (pagination: string | undefined | null) {
    let url = `/datasets/${datasetId}/items`;
    if (pagination) {
      url += `?pagination=${pagination}`;
    }
    let res = await devApi.get<{
      pagination: string | null | undefined;
      items: DatasetItem[];
    }>(url);
    return res.data;
  };
  const paginate = usePaginate<DatasetItem>({ loader });

  useEffect(() => {
    async function loadData() {
      setDataset(null);
      let res = await devApi.get<{ dataset: Dataset }>(
        "/datasets/" + datasetId
      );
      setDataset(res.data.dataset);
    }
    loadData();
  }, [datasetId]);

  if (!dataset) {
    return (
      <Box textAlign={"center"} mt={8}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Box>
    );
  }

  return (
    <Box pt={4} px={8} pb="12">
      <Flex alignItems={"end"} justifyContent={"space-between"}>
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink href={orgId ? `/${orgId}/datasets` : "/datasets"}>
              Datasets
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href={location.pathname}>
              {dataset.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Heading>{dataset.name}</Heading>
        <Flex fontFamily="monospace" alignItems={"center"}>
          <Text
            fontWeight={"light"}
            color={"gray.500"}
            mr={2}
            userSelect={"none"}
          >
            ID:
          </Text>
          <Text>{dataset.datasetId}</Text>
          <IconButton
            ml={2}
            size={"xs"}
            aria-label="copy dataset id to clipboard"
            icon={<CopyIcon />}
            onClick={async () => {
              try {
                await window.navigator.clipboard.writeText(dataset.datasetId);
                toast("Dataset id copied!", dataset.datasetId);
              } catch (e) {
                // TODO
              }
            }}
          />
        </Flex>
      </Flex>
      <Divider my="4" />
      <Flex mb={2} justifyContent={"space-between"}>
        <Text color={"gray.400"}></Text>
        <Pagination
          currentPage={paginate.currentPage}
          numPages={paginate.numPages}
          endOfData={paginate.isEndOfData}
          onPaginate={paginate.gotoPage}
        />
      </Flex>
      <ItemTable dataset={dataset} items={paginate.items} />
    </Box>
  );
}
