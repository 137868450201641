import {
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { Button, Flex, Icon, IconProps, Text } from "@chakra-ui/react";

export default function Pagination({
  numPages,
  currentPage,
  endOfData,
  onPaginate,
}: {
  numPages: number;
  currentPage: number;
  endOfData?: boolean;
  onPaginate: (page: number) => void;
}) {
  currentPage = Math.max(0, Math.min(numPages - 1, currentPage));
  let pageIcons: number[] = [
    currentPage - 2,
    currentPage - 1,
    currentPage,
    currentPage + 1,
    currentPage + 2,
  ];
  pageIcons = pageIcons.filter((page) => page >= 0 && page < numPages);
  switch (pageIcons[0]) {
    case 0:
      break;
    case 1:
      pageIcons = [0, ...pageIcons];
      break;
    case 2:
      pageIcons = [0, 1, ...pageIcons];
      break;
    default:
      pageIcons = [0, 1, -1, ...pageIcons];
  }
  switch (numPages - pageIcons[pageIcons.length - 1] - 1) {
    case 0:
      break;
    case 1:
      pageIcons.push(numPages - 1);
      break;
    case 2:
      pageIcons.push(numPages - 2, numPages - 1);
      break;
    default:
      pageIcons.push(-2, numPages - 2, numPages - 1);
  }
  if (!endOfData) {
    pageIcons.push(-3);
  }
  return (
    <Flex alignItems={"center"}>
      <NavIcon
        isValid={currentPage > 0}
        direction="left"
        onClick={() => onPaginate(currentPage - 1)}
      />
      {pageIcons.map((page) =>
        page < 0 ? (
          <Text key={page}>...</Text>
        ) : (
          <Text
            key={page}
            px="1"
            mx="1"
            cursor={page < 0 || page === currentPage ? undefined : "pointer"}
            fontWeight={page === currentPage ? "bold" : "light"}
            color={page === currentPage ? "white" : "gray.300"}
            _hover={{
              color: "gray.100",
            }}
            onClick={() => onPaginate(page)}
          >
            {page + 1}
          </Text>
        )
      )}
      <NavIcon
        isValid={currentPage !== numPages - 1 || !endOfData}
        onClick={() => onPaginate(currentPage + 1)}
        direction="right"
      />
    </Flex>
  );
}

function NavIcon({
  isValid,
  onClick,
  direction,
}: {
  isValid: boolean;
  onClick: () => void;
  direction: string;
}) {
  return (
    <Icon
      as={direction === "left" ? ChevronLeftIcon : ChevronRightIcon}
      fontSize={"3xl"}
      cursor={isValid ? "pointer" : undefined}
      px="0.5"
      color={isValid ? "gray.300" : "gray.600"}
      _hover={{
        color: isValid ? "gray.100" : undefined,
      }}
      onClick={onClick}
    />
  );
}
