import { RouteObject } from "react-router-dom";
import ApiKeys from "./console/api-keys";
import { CreateOrganization } from "./console/create-organization";
import Datasets from "./console/datasets";
import ConsoleLayout from "./console/layout";
import Members from "./console/members";
import Organizations from "./console/organizations";
import Projects from "./console/projects";
import OrgSettings from "./console/settings-org";
import UserSettings from "./console/settings-user";
import ErrorPage from "./error-page";
import Apis from "./home/apis";
import Docs from "./home/docs";
import Layout from "./home/layout";
import PasswordReset from "./home/password-reset";
import SignIn from "./home/signin";
import SignUp from "./home/signup";
import Tutorial from "./home/tutorial";
import TransactionLayout from "./transaction/layout";
import OrgInvitationAccept from "./transaction/org-invitation-accept";
import Dataset from "./console/dataset";

const routes: RouteObject[] = [
  {
    element: <Layout />,
    children: [
      {
        path: "/signin",
        element: <SignIn />,
      },
      {
        path: "/signup",
        element: <SignUp />,
      },
      {
        path: "/docs",
        element: <Docs />,
      },
      {
        path: "/tutorial",
        element: <Tutorial />,
      },
      {
        path: "/apis",
        element: <Apis />,
      },
      {
        path: "/password-reset",
        element: <PasswordReset />,
      },
    ],
    errorElement: <ErrorPage />,
  },
  {
    path: "/",
    element: <ConsoleLayout />,
    children: [
      {
        index: true,
        element: <Organizations />,
      },
      {
        path: "projects",
        element: <Projects />,
      },
      {
        path: "datasets",
        element: <Datasets />,
      },
      {
        path: "datasets/:datasetId",
        element: <Dataset />,
      },
      {
        path: "api-keys",
        element: <ApiKeys />,
      },
      {
        path: "settings",
        element: <UserSettings />,
      },
      {
        path: "organizations",
        element: <Organizations />,
      },
      {
        path: "organizations/create",
        element: <CreateOrganization />,
      },
    ],
  },
  {
    path: "/:orgId",
    element: <ConsoleLayout />,
    children: [
      {
        index: true,
        element: <Members />,
      },
      {
        path: "members",
        element: <Members />,
      },
      {
        path: "projects",
        element: <Projects />,
      },
      {
        path: "datasets",
        element: <Datasets />,
      },
      {
        path: "datasets/:datasetId",
        element: <Dataset />,
      },
      {
        path: "settings",
        element: <OrgSettings />,
      },
    ],
  },
  {
    element: <TransactionLayout />,
    children: [
      {
        path: "/organizations/:orgId/members/:memberId/accept",
        element: <OrgInvitationAccept />,
      },
    ],
  },
];
export default routes;
