import { Box, Heading, Image, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
export * from "./user";
export * from "./toast";

export const useCountDown = (
  initial: number
): [number, (seconds: number) => void] => {
  const [countDownDate, setCountDownDate] = useState(
    new Date().getTime() + initial * 1000
  );

  const [countDown, setCountDown] = useState(initial);

  const resetCountDown = (seconds: number) => {
    setCountDownDate(new Date().getTime() + seconds * 1000);
    setCountDown(seconds);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      let remaining = countDownDate - new Date().getTime();

      remaining = Math.max(0, Math.round(remaining / 1000));

      if (remaining !== countDown) {
        setCountDown(remaining);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [countDownDate]);

  return [countDown, resetCountDown];
};

export function isValidId(id: string) {
  if (id.length !== 22) {
    return false;
  }
  for (let i = 0; i < id.length; i++) {
    let c = id.charCodeAt(i);

    // check for '-' and '_'
    if (c === 45 || c === 95) {
      continue;
    }

    // check for 0-9
    if (c >= 48 && c <= 57) {
      continue;
    }

    // check for A-Z
    if (c >= 65 && c <= 90) {
      continue;
    }

    // check for a-z
    if (c >= 97 && c <= 122) {
      continue;
    }

    return false;
  }

  return true;
}
