import { AddIcon, CopyIcon } from "@chakra-ui/icons";
import {
  Button,
  Container,
  Divider,
  Heading,
  Text,
  Link as RemixLink,
  Box,
  Spinner,
  HStack,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
  Flex,
  IconButton,
  Thead,
  Th,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useSuccessToast } from "~/utils";
import { devApi } from "~/utils/http";
import { Dataset } from "./dataset";

export default function Datasets() {
  const [datasets, setDatasets] = useState<Dataset[] | null>(null);
  const params = useParams();
  const { orgId } = params;
  const toast = useSuccessToast();
  const location = useLocation();
  useEffect(() => {
    async function loadData() {
      setDatasets(null);
      let url = "/datasets";
      if (orgId) {
        url += "?organizationId=" + orgId;
      }
      let res = await devApi.get<{ datasets: Dataset[] }>(url);
      setDatasets(res.data.datasets);
    }
    loadData();
  }, [orgId]);

  if (datasets === null) {
    return (
      <Box textAlign={"center"} mt={8}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Box>
    );
  }

  if (datasets.length === 0) {
    return (
      <Container mt={8} maxW={"lg"}>
        <Heading>About datasets</Heading>
        <Divider my={2} />
        <Text>
          Datasets are data attribute definitions to store the input or output
          data, which can be provided to projects. To create a dataset, please
          follow the API or SDK in our{" "}
          <RemixLink as={Link} to="/tutorial" color={"blue.200"}>
            tutorial
          </RemixLink>
          .
        </Text>
      </Container>
    );
  }
  return (
    <Container maxW="6xl">
      <HStack px={4} mt={8} mb={2} justifyContent={"space-between"}>
        <Heading size={"md"}>Datasets</Heading>
      </HStack>

      <TableContainer>
        <Table size="sm" mt={4} variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>ID</Th>
              <Th>Items</Th>
              <Th isNumeric>Created</Th>
            </Tr>
          </Thead>
          <Tbody>
            {datasets.map((dataset) => (
              <Tr key={dataset.datasetId}>
                <Td>
                  <HStack>
                    <RemixLink
                      as={Link}
                      color="blue.200"
                      to={location.pathname + "/" + dataset.datasetId}
                    >
                      {dataset.name}
                    </RemixLink>
                  </HStack>
                </Td>
                <Td>
                  <Flex fontFamily="monospace" alignItems={"center"}>
                    <Text>{dataset.datasetId}</Text>
                    <IconButton
                      ml={2}
                      size={"xs"}
                      aria-label="copy secret key to clipboard"
                      icon={<CopyIcon />}
                      onClick={async () => {
                        try {
                          await window.navigator.clipboard.writeText(
                            dataset.datasetId
                          );
                          toast("Dataset id copied!", dataset.datasetId);
                        } catch (e) {
                          // TODO
                        }
                      }}
                    />
                  </Flex>
                </Td>
                <Td>{dataset.numItems}</Td>
                <Td isNumeric>
                  {new Date(dataset.createdAt / 1000).toLocaleDateString()}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Container>
  );
}
