import {
  Box,
  Button,
  Flex,
  Heading,
  InputRightElement,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Form, Link, useNavigate } from "react-router-dom";
import { z } from "zod";
import FormInput from "~/ui/Form/FormInput";
import { clientApi } from "~/utils/http";

const signUpSchema = z
  .object({
    name: z
      .string()
      .trim()
      .min(1, { message: "Please input your name" })
      .min(2, { message: "Name should contain at least 2 characters" }),
    email: z
      .string()
      .trim()
      .min(1, { message: "Please input your email" })
      .max(128, { message: "Email is too long (over 128)" })
      .email(),
    password: z
      .string()
      .min(1, { message: "Please input your password" })
      .min(6, { message: "Length of password should be at least 6" })
      .max(32, "Length of password should not exceed 32"),
    passwordConfirm: z
      .string()
      .min(1, { message: "Please input your confirm password" }),
  })
  .refine(({ password, passwordConfirm }) => password === passwordConfirm, {
    path: ["passwordConfirm"],
    message: "Passwords must match",
  });

const codeSchema = z.object({
  code: z.string().min(1, { message: "Please input verification code" }),
});

type SignUpHandler = (data: z.infer<typeof signUpSchema>) => void;

function SignUpForm({ onSubmit }: { onSubmit: SignUpHandler }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof signUpSchema>>({
    resolver: zodResolver(signUpSchema),
  });

  return (
    <Form
      method="post"
      onSubmit={handleSubmit(async (data) => {
        try {
          await clientApi.post("/users/code", {
            email: data.email,
          });
          onSubmit(data);
        } catch (e) {
          // TODO
        }
      })}
    >
      <Stack spacing={4}>
        <FormInput
          {...register("name")}
          label="Name"
          type="text"
          error={errors.name?.message}
        />
        <FormInput
          {...register("email")}
          label="Email"
          type="email"
          error={errors.email?.message}
        />
        <FormInput
          {...register("password")}
          label="Password"
          type="password"
          error={errors.password?.message}
        />
        <FormInput
          {...register("passwordConfirm")}
          label="Confirm Password"
          type="password"
          error={errors.passwordConfirm?.message}
        />

        <Stack spacing={10} pt={2}>
          <Button type="submit" size="lg" colorScheme="teal">
            Sign up
          </Button>
        </Stack>
      </Stack>
    </Form>
  );
}

function CodeVerificationForm({
  data,
}: {
  data: z.infer<typeof signUpSchema>;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof codeSchema>>({
    resolver: zodResolver(codeSchema),
  });
  const navigate = useNavigate();
  return (
    <Form
      method="post"
      onSubmit={handleSubmit(async (codeData) => {
        try {
          await clientApi.post("/users", {
            name: data.name,
            email: data.email,
            password: data.password,
            code: codeData.code,
          });
          await clientApi.post("/users/login", {
            email: data.email,
            password: data.password,
            remember: "",
          });
          navigate("/");
        } catch (e) {
          // TODO
        }
      })}
    >
      <Stack spacing={4}>
        <Text textAlign={"center"} mb={4}>
          Please check your email for verification code
          <br />
          <strong>{data.email}</strong>
        </Text>
        <FormInput
          {...register("code")}
          placeholder="Code"
          type="text"
          error={errors.code?.message}
        >
          <InputRightElement width="4.5rem">
            <Button
              colorScheme="blue"
              mr={2}
              h="1.75rem"
              size="sm"
              onClick={() => {}}
            >
              Resend
            </Button>
          </InputRightElement>
        </FormInput>
        <Stack spacing={10} pt={2}>
          <Button type="submit" size="lg" colorScheme="teal">
            Confirm
          </Button>
        </Stack>
      </Stack>
    </Form>
  );
}

export default function SignUp() {
  const [signUpData, setSignUpData] = useState<z.infer<
    typeof signUpSchema
  > | null>(null);
  return (
    <Flex justify={"center"} bg={useColorModeValue("gray.50", "gray.800")}>
      <Helmet>
        <title>Sath | sign up</title>
      </Helmet>
      <Stack width="440px" spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"} textAlign={"center"}>
            Sign up
          </Heading>
          <Text fontSize={"lg"} color={"gray.600"}>
            to enjoy all of our cool <Link to="#">features</Link> ✌️
          </Text>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          {signUpData ? (
            <CodeVerificationForm data={signUpData} />
          ) : (
            <SignUpForm onSubmit={(data) => setSignUpData(data)} />
          )}
          <Stack pt={6}>
            <Text align={"center"}>
              Already a user?{" "}
              <Link to="/signin" color={"blue.400"}>
                Sign in
              </Link>
            </Text>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
