import {
  ChakraBaseProvider,
  ChakraProvider,
  DarkMode,
  extendTheme,
} from "@chakra-ui/react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import routes from "./routes";
import { Helmet } from "react-helmet";

const router = createBrowserRouter(routes);

const config = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};
const theme = extendTheme({ config });

export const App = () => (
  <ChakraBaseProvider theme={theme}>
    <Helmet>
      <title>Sath</title>
    </Helmet>
    <RouterProvider router={router} />
  </ChakraBaseProvider>
);
