import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Image,
  Box,
} from "@chakra-ui/react";

export default function UnimplementedModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>This feature is not implemented yet.</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box p={8}>
            <Image
              src={
                process.env.PUBLIC_URL + "/under-construction-barrier-icon.png"
              }
            />
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
