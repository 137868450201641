import { ChevronDownIcon, EmailIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Container,
  HStack,
  Heading,
  IconButton,
  InputRightAddon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, useParams } from "react-router-dom";
import { z } from "zod";
import UnimplementedModal from "~/ui/Errors/UnimplementedModal";
import FormInput from "~/ui/Form/FormInput";
import { Org as BasicOrg, mustGetUser, useSuccessToast } from "~/utils";
import { devApi, handleError } from "~/utils/http";

const schema = z.object({
  name: z
    .string()
    .min(1, { message: "Please input organization name" })
    .max(32, { message: "Organization name should be no longer than 32" }),
  description: z
    .string()
    .max(1000, { message: "Description should be no longer than 1000" })
    .optional(),
});

interface Member {
  userId: string;
  name: string;
  email: string;
  avatar: string;
  role: string;
  activated: boolean;
}

interface Org extends BasicOrg {
  members: Member[];
}

function MemberColumn({ orgId, member }: { orgId: string; member: Member }) {
  const disclosure = useDisclosure();
  const toast = useSuccessToast();
  const user = mustGetUser();
  const role =
    user.organizations.find((org) => org.organizationId === orgId)?.role ||
    "visitor";
  return (
    <HStack justifyContent={"end"}>
      <UnimplementedModal
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
      />
      <Text>{member.role}</Text>

      {member.userId === user.userId ? (
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            colorScheme="teal"
            size="sm"
            isRound={true}
            variant={"ghost"}
            icon={<ChevronDownIcon />}
          />
          {member.role === "owner" ? (
            <MenuList>
              <MenuItem onClick={disclosure.onOpen} color={"red"}>
                Transfer ownership...
              </MenuItem>
            </MenuList>
          ) : member.activated ? (
            <MenuList>
              <MenuItem onClick={disclosure.onOpen} color={"red"}>
                Exit organization...
              </MenuItem>
            </MenuList>
          ) : (
            <MenuList>
              <MenuItem onClick={disclosure.onOpen}>
                Accept invitation...
              </MenuItem>
              <MenuItem onClick={disclosure.onOpen} color={"red"}>
                Decline invitation...
              </MenuItem>
            </MenuList>
          )}
        </Menu>
      ) : role === "admin" || role === "owner" ? (
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            colorScheme="teal"
            size="sm"
            isRound={true}
            variant={"ghost"}
            icon={<ChevronDownIcon />}
            isDisabled={role !== "owner" && member.role === "owner"}
          />
          {member.activated ? (
            <MenuList>
              <MenuItem onClick={disclosure.onOpen}>Change role...</MenuItem>
              <MenuItem onClick={disclosure.onOpen} color={"red"}>
                Remove from organization...
              </MenuItem>
            </MenuList>
          ) : (
            <MenuList>
              <MenuItem
                onClick={async () => {
                  await devApi.post("organizations/members/resend-invite", {
                    organizationId: orgId,
                    userId: member.userId,
                  });
                  toast("Invitation email has been sent");
                }}
              >
                Resend invitation
              </MenuItem>
              <MenuItem onClick={disclosure.onOpen}>Change role...</MenuItem>
              <MenuItem onClick={disclosure.onOpen} color={"red"}>
                Cancel invitation...
              </MenuItem>
            </MenuList>
          )}
        </Menu>
      ) : null}
    </HStack>
  );
}

function InviteMemberModal({
  isOpen,
  onClose,
  onSubmit,
  org,
  members,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (member: Member) => void;
  org: Org;
  members: Member[];
}) {
  const schema = z.object({
    email: z
      .string()
      .trim()
      .min(1, "Please input your new member's email address")
      .email("Invalid email format")
      .refine(
        (email) => members.every((member) => member.email !== email),
        (email) => ({
          message: `${email} is already a member of ${org.name}`,
        })
      ),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });
  const toast = useSuccessToast();

  useEffect(() => {
    reset();
  }, [isOpen]);

  return (
    <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        as={Form}
        onSubmit={handleSubmit(async (data) => {
          try {
            let res = await devApi.post<{ member: Member }>(
              "/organizations/members/invite",
              {
                email: data.email,
                organizationId: org.organizationId,
              }
            );
            onSubmit(res.data.member);
            toast("Member invited", `An email has been sent to ${data.email}`);
            onClose();
          } catch (e) {
            handleError(e, setError);
          }
        })}
      >
        <ModalCloseButton />
        <ModalBody>
          <VStack mt={16} mb={24}>
            <EmailIcon fontSize={"4xl"} />
            <Heading size="md">Invite a member to {org.name}</Heading>
            <Box mt={12} px={4} w="100%">
              <FormInput
                label="Your new member's email address"
                borderRightRadius={"none"}
                error={errors.email?.message}
                {...register("email")}
              >
                <InputRightAddon p={0}>
                  <Button
                    type="submit"
                    colorScheme="blue"
                    w={24}
                    borderLeftRadius={"none"}
                  >
                    Invite
                  </Button>
                </InputRightAddon>
              </FormInput>
              {errors.root && (
                <Text mt={2} color="red.500" fontSize={"sm"}>
                  {errors.root.message}
                </Text>
              )}
            </Box>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default function Members() {
  const [org, setOrg] = useState<Org | null>(null);
  const [ts, setTs] = useState(new Date().getTime());
  const inviteDisclosure = useDisclosure();
  const params = useParams();
  const { orgId } = params;
  useEffect(() => {
    async function loadData() {
      setOrg(null);
      let res = await devApi.get<{ organization: Org }>(
        `/organizations/${orgId}`
      );
      setOrg(res.data.organization);
    }
    loadData();
  }, [orgId, ts]);
  if (org === null) {
    return (
      <Box textAlign={"center"} mt={8}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Box>
    );
  }
  return (
    <Container maxW="6xl">
      <HStack px={4} mt={8} mb={2} justifyContent={"space-between"}>
        <Heading size={"md"}>Members</Heading>
        <Button size="sm" colorScheme="teal" onClick={inviteDisclosure.onOpen}>
          Invite member
        </Button>
        <InviteMemberModal
          isOpen={inviteDisclosure.isOpen}
          onClose={inviteDisclosure.onClose}
          onSubmit={() => setTs(new Date().getTime())}
          org={org}
          members={org.members}
        />
      </HStack>

      <TableContainer>
        <Table size="sm" mt={4} variant="simple">
          <Tbody>
            {org.members.map((member) => (
              <Tr key={member.userId}>
                <Td>
                  <HStack>
                    <Avatar
                      size={"sm"}
                      src={member.avatar}
                      name={member.name}
                    />
                    <Text>{member.name}</Text>
                  </HStack>
                </Td>
                <Td>{member.email}</Td>
                <Td>
                  {!member.activated && (
                    <Text textColor={"gray.500"}>
                      Waiting for user to accept invitation
                    </Text>
                  )}
                </Td>
                <Td isNumeric>
                  <MemberColumn orgId={org.organizationId} member={member} />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Container>
  );
}
