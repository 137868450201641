import { AddIcon, CopyIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Flex,
  HStack,
  Heading,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {
  Org,
  mustGetUser,
  useSuccessToast,
  useUnderconstructionToast,
} from "~/utils";

export default function Organizations() {
  const user = mustGetUser();
  const toast = useSuccessToast();
  const underconstruction = useUnderconstructionToast();
  const handleLeaveOrg = (org: Org) => {
    if (
      confirm(
        `Are you positive you want to leave ${org.name}? You will lose access to all repositories and teams.`
      )
    ) {
      // Save it!
      underconstruction();
    } else {
      // Do nothing!
    }
  };

  if (user.organizations.length === 0) {
    return (
      <Container mt={8} maxW={"lg"}>
        <Heading>About organizations</Heading>
        <Divider my={2} />
        <Text>
          Organizations are shared accounts where businesses and open-source
          projects can collaborate across many projects at once, with
          sophisticated security and administrative features.
        </Text>
        <Button
          mt={4}
          leftIcon={<AddIcon />}
          colorScheme="blue"
          variant="solid"
          as={Link}
          to="/organizations/create"
        >
          Create organization
        </Button>
      </Container>
    );
  }

  return (
    <Box>
      <Container maxW={"6xl"}>
        <HStack px={4} mt={8} mb={2} justifyContent={"space-between"}>
          <Heading size={"md"}>Organizations</Heading>
          <Button
            as={Link}
            to="/organizations/create"
            size="sm"
            colorScheme="teal"
          >
            Create Organization
          </Button>
        </HStack>

        <Divider mt={4} />
        <TableContainer>
          <Table size="sm" mt={4} variant="simple">
            <Tbody>
              {user.organizations.map((org) => (
                <Tr key={org.organizationId}>
                  <Td>
                    <HStack>
                      <Avatar size={"sm"} src={org.avatar} name={org.name} />
                      <VStack
                        alignItems={"start"}
                        spacing={0}
                        ml={2}
                        fontSize={"sm"}
                        as={Link}
                        to={`/${org.organizationId}`}
                        color={"blue.200"}
                      >
                        <Text
                          textOverflow={"ellipsis"}
                          overflow={"hidden"}
                          maxW={48}
                          fontWeight={"bold"}
                        >
                          {org.name}
                        </Text>
                        <Text
                          textOverflow={"ellipsis"}
                          overflow={"hidden"}
                          maxW={48}
                          fontWeight={"normal"}
                        >
                          {org.verifyedName}
                        </Text>
                      </VStack>
                    </HStack>
                  </Td>
                  <Td>
                    <Flex fontFamily="monospace" alignItems={"center"}>
                      <Text
                        fontWeight={"light"}
                        color={"gray.500"}
                        mr={2}
                        userSelect={"none"}
                      >
                        ID:
                      </Text>
                      <Text>{org.organizationId}</Text>
                      <IconButton
                        ml={2}
                        size={"xs"}
                        aria-label="copy secret key to clipboard"
                        icon={<CopyIcon />}
                        onClick={async () => {
                          try {
                            await window.navigator.clipboard.writeText(
                              org.organizationId
                            );
                            toast(
                              "Organization id copied!",
                              org.organizationId
                            );
                          } catch (e) {
                            // TODO
                          }
                        }}
                      />
                    </Flex>
                  </Td>
                  <Td isNumeric>
                    <HStack justifyContent={"end"}>
                      <Button
                        as={Link}
                        to={`/${org.organizationId}/settings`}
                        target="_blank"
                        size="sm"
                      >
                        Settings
                      </Button>
                      <Button
                        size="sm"
                        color="red"
                        onClick={() => {
                          handleLeaveOrg(org);
                        }}
                      >
                        Leave
                      </Button>
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  );
}
