import { Box, Button, Spinner, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { clientApi } from "~/utils/http";

export default function OrgInvitationAccept() {
  const params = useParams();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const { memberId, orgId } = params;
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get("code");

    if (!memberId || !orgId || !code) {
      setError("invalid param");
      return;
    }

    clientApi
      .post("/organizations/members/accept", {
        organizationId: orgId,
        userId: memberId,
        code: code,
      })
      .then(() => {
        setSuccess(true);
      })
      .catch((e) => {
        // TODO: add error handling
        setError("unknown error");
      });
  }, []);

  if (error) {
    return (
      <Box color={"red.500"} textAlign={"center"}>
        {error}
      </Box>
    );
  }

  if (!success) {
    return (
      <Box color={"red.500"} textAlign={"center"}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Box>
    );
  }

  return (
    <Box textAlign={"center"}>
      <Text>You&apos;ve successfully joined the organization.</Text>
      <Button mt={4} as={Link} to="/organizations" colorScheme="teal">
        View organizations
      </Button>
    </Box>
  );
}
