import { useEffect, useState } from "react";

interface LoaderResult<T> {
  pagination: string | null | undefined;
  items: T[];
}

export function usePaginate<T>({
  loader,
}: {
  loader: (
    pagination: string | undefined | null
  ) => LoaderResult<T> | Promise<LoaderResult<T>>;
}) {
  const [keys, setKeys] = useState<(string | undefined | null)[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isEndOfData, setIsEndOfData] = useState(false);
  const [items, setItems] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [numPages, setNumPages] = useState(0);

  const loadData = async (page: number) => {
    if (page > keys.length) {
      return;
    }
    let pagination = keys[page - 1];
    setIsLoading(true);
    try {
      let res = await loader(pagination);
      if (!res.pagination) {
        setIsEndOfData(true);
      }
      if (page == keys.length) {
        setKeys([...keys, res.pagination]);
        setNumPages(numPages + 1);
      }
      if (res.items.length > 0) {
        setItems(res.items);
        setCurrentPage(page);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const gotoPage = (page: number) => {
    if (page < 0 || page > keys.length) {
      return;
    } else if (page == keys.length && isEndOfData) {
      return;
    } else {
      loadData(page);
    }
  };

  useEffect(() => {
    loadData(0);
  }, []);

  return {
    currentPage,
    gotoPage,
    items,
    isEndOfData,
    isLoading,
    numPages,
  };
}
