import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Heading,
  InputRightElement,
  Stack,
  Text,
  useColorModeValue,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";

import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Link, useLocation, useNavigate } from "react-router-dom";
import * as z from "zod";
import FormInput from "~/ui/Form/FormInput";
import { clientApi } from "~/utils/http";
import { Helmet } from "react-helmet";
import axios from "axios";

const schema = z.object({
  email: z
    .string()
    .trim()
    .min(1, { message: "Please input your email" })
    .email({ message: "Invalid email format" }),
  password: z.string().min(1, "Please input your password"),
  remember: z.preprocess((value) => value || "", z.string()),
});

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    setFocus,
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Flex bg={useColorModeValue("gray.50", "gray.800")}>
      <Helmet>
        <title>Sath | sign in</title>
      </Helmet>
      <Stack spacing={8} mx={"auto"} width="440px" maxW={"lg"} py={20} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>Sign in to your account</Heading>
          <Text fontSize={"lg"} color={"gray.600"}>
            to enjoy all of our cool <Link to="#">features</Link> ✌️
          </Text>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Form
            method="post"
            onSubmit={handleSubmit(async (data) => {
              try {
                await clientApi.post("/users/login", {
                  email: data.email,
                  password: data.password,
                  remember: data.remember,
                });
                let params = new URLSearchParams(location.search);
                let next = params.get("next") || "/";
                navigate(next);
              } catch (e) {
                // TODO
                if (axios.isAxiosError(e)) {
                  setError("root", {
                    type: e.response?.status + "",
                    message: "Your email or password does not match our record",
                  });
                  setFocus("password");
                } else {
                  setError("root", {
                    type: "unkown",
                    message: "unknown error",
                  });
                }
              }
            })}
          >
            <Stack spacing={4}>
              <FormInput
                label="Email address"
                {...register("email")}
                error={errors.email?.message}
              />
              <FormInput
                label={
                  <HStack justifyContent={"space-between"}>
                    <Text>Password</Text>
                    <ChakraLink
                      as={Link}
                      to={"/password-reset"}
                      color={"blue.600"}
                      fontSize={"sm"}
                    >
                      Forget password?
                    </ChakraLink>
                  </HStack>
                }
                type={showPassword ? "text" : "password"}
                {...register("password")}
                error={errors.password?.message}
              >
                <InputRightElement h={"full"}>
                  <Button
                    variant={"ghost"}
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }
                  >
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </FormInput>
              <Stack>
                <Stack
                  direction={{ base: "column", sm: "row" }}
                  align={"start"}
                  justify={"space-between"}
                >
                  <Checkbox {...register("remember")} value="on">
                    Remember me
                  </Checkbox>
                </Stack>
                <Button
                  isLoading={isSubmitting}
                  mt={10}
                  type="submit"
                  colorScheme="teal"
                >
                  Sign in
                </Button>
              </Stack>
              {errors.root && (
                <Text mt={4} color="red">
                  {errors.root.message}
                </Text>
              )}
              <Stack pt={6}>
                <Text align={"center"}>
                  New to Sath?{" "}
                  <Link to="/signup" color={"blue.400"}>
                    Sign up
                  </Link>
                </Text>
              </Stack>
            </Stack>
          </Form>
        </Box>
      </Stack>
    </Flex>
  );
}
