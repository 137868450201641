import { AddIcon, CopyIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, useParams } from "react-router-dom";
import { z } from "zod";
import FormInput from "~/ui/Form/FormInput";
import { devApi } from "~/utils/http";
import { Org, mustGetUser, useSuccessToast } from "~/utils";
import invariant from "tiny-invariant";

interface Key {
  token: string;
  name: string;
  userId: string;
  createdAt: number;
  organizationId?: string;
  lastUsedAt?: number;
}

const schema = z.object({
  name: z
    .string()
    .min(1, { message: "Please input key name" })
    .max(32, { message: "Key name should be no longer than 32" }),
  organizationId: z.string(),
});

function NewApiKeyModal({
  isOpen,
  onClose,
  onSubmit,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (key: Key) => void;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });
  const toast = useSuccessToast();
  const [isLoading, setLoading] = useState(false);
  const [secret, setSecret] = useState<string>("");
  const user = mustGetUser();
  const orgs = user.organizations.filter((org) =>
    ["member", "admin", "owner"].some((role) => role === org.role)
  );
  useEffect(() => {
    setLoading(false);
    setSecret("");
  }, [isOpen]);

  return (
    <Modal size={"lg"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        as={Form}
        onSubmit={handleSubmit(async (data) => {
          let res = await devApi.post<{ key: Key & { secret: string } }>(
            "/keys",
            {
              name: data.name,
              organizationId: data.organizationId,
            }
          );
          const { secret, ...rest } = res.data.key;
          setSecret(secret);
          onSubmit(rest);
        })}
      >
        <ModalHeader>Create new secret key</ModalHeader>
        <ModalCloseButton />
        {secret ? (
          <ModalBody>
            <Text>
              {`Please save this secret key somewhere safe and accessible. For
              security reasons, `}
              <strong>{"you won't be able to view it again"}</strong>
              {` through your
              Sath account. If you lose this secret key, you'll need to
              generate a new one.`}
            </Text>
            <HStack my={4}>
              <Input
                size="sm"
                value={secret}
                readOnly
                onFocus={(e) => e.target.select()}
              />
              <IconButton
                colorScheme="teal"
                size={"sm"}
                aria-label="copy secret key to clipboard"
                icon={<CopyIcon />}
                onClick={async () => {
                  try {
                    await window.navigator.clipboard.writeText(secret);
                    toast("API key copied!");
                  } catch (e) {
                    // TODO
                  }
                }}
              />
            </HStack>
          </ModalBody>
        ) : (
          <ModalBody>
            <VStack spacing={4}>
              <FormInput
                label="API key name"
                {...register("name")}
                isRequired
              />
              <FormControl isInvalid={!!errors.organizationId}>
                <FormLabel>Assign API key to organization</FormLabel>
                <Select {...register("organizationId")}>
                  <option value={""}>None</option>
                  {orgs.map((org) => (
                    <option key={org.organizationId} value={org.organizationId}>
                      {org.name}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>
                  {errors.organizationId?.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>
          </ModalBody>
        )}

        <ModalFooter>
          {secret ? (
            <Button size="sm" colorScheme="blue" onClick={onClose}>
              Done
            </Button>
          ) : (
            <Button
              size="sm"
              isLoading={isLoading}
              colorScheme="blue"
              type="submit"
            >
              Create
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function RevokeApiKeyModal({
  onClose,
  onSubmit,
  apikey,
}: {
  onClose: () => void;
  onSubmit: (key: Key) => void;
  apikey: Key | null;
}) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [apikey]);

  const handleSubmit = async () => {
    if (apikey === null) {
      return;
    }
    setLoading(true);
    await devApi.post("/keys/delete", {
      createdAt: apikey.createdAt,
    });
    setLoading(false);
    onSubmit(apikey);
    onClose();
  };

  return (
    <Modal size={"lg"} isOpen={apikey !== null} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Revoke secret key</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            This API key will immediately be disabled. API requests made using
            this key will be rejected, which could cause any systems still
            depending on it to break. Once revoked, you&apos;ll no longer be
            able to view or modify this API key.
          </Text>
          <Input my={4} value={apikey?.token} readOnly />
        </ModalBody>

        <ModalFooter>
          <Button size="sm" mr={4} onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="sm"
            colorScheme="red"
            isLoading={loading}
            onClick={handleSubmit}
          >
            Revoke key
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default function ApiKeys() {
  const creationDisclosure = useDisclosure();
  const revokeDisclosure = useDisclosure();
  const [keys, setKeys] = useState<Key[] | null>(null);
  const [keyToRevoke, setKeyToRevoke] = useState<Key | null>(null);
  const params = useParams();
  const { orgId } = params;
  const user = mustGetUser();
  const orgMap = user.organizations.reduce((orgmap, org) => {
    orgmap.set(org.organizationId, org.name);
    return orgmap;
  }, new Map<string, string>());

  useEffect(() => {
    async function loadData() {
      let url = "/keys";
      if (orgId) {
        url += `?organizationId=${orgId}`;
      }
      let kres = await devApi.get<{ keys: Key[] }>(url);
      setKeys(kres.data.keys);
    }
    loadData();
  }, []);

  if (keys === null) {
    return (
      <Box textAlign={"center"} mt={8}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Box>
    );
  }

  const handleKeyCreation = (key: Key) => {
    setKeys([...keys, key]);
  };

  const handleKeyRevoke = (key: Key) => {
    let newKeys = keys.filter((k) => k.createdAt !== key.createdAt);
    setKeys(newKeys);
  };

  const handleRevokeClose = () => {
    setKeyToRevoke(null);
  };

  return (
    <Container maxW={"3xl"}>
      <NewApiKeyModal
        isOpen={creationDisclosure.isOpen}
        onClose={creationDisclosure.onClose}
        onSubmit={handleKeyCreation}
      />
      <RevokeApiKeyModal
        apikey={keyToRevoke}
        onClose={handleRevokeClose}
        onSubmit={handleKeyRevoke}
      />
      <VStack mt={4} spacing={4} alignItems={"start"}>
        <Heading>API keys</Heading>
        <Text>
          Your secret API keys are listed below. Please note that we do not
          display your secret API keys again after you generate them.
        </Text>
        <Text>
          Do not share your API key with others, or expose it in the browser or
          other client-side code. In order to protect the security of your
          account, Sath may also automatically disable any API key that
          we&apos;ve found has leaked publicly.
        </Text>
      </VStack>
      {keys.length > 0 && (
        <Table my={4} size={"sm"} variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Key</Th>
              <Th>Organization</Th>
              <Th>Created</Th>
              <Th>Last used</Th>
              <Th isNumeric></Th>
            </Tr>
          </Thead>
          <Tbody>
            {keys.map((key) => (
              <Tr key={key.createdAt}>
                <Td>{key.name}</Td>
                <Td fontFamily={"monospace"}>{key.token}</Td>
                <Td>
                  {key.organizationId ? orgMap.get(key.organizationId) : null}
                </Td>
                <Td>{new Date(key.createdAt / 1000).toLocaleDateString()}</Td>
                <Td>
                  {key.lastUsedAt
                    ? new Date(key.lastUsedAt / 1000).toLocaleDateString()
                    : "Never"}
                </Td>
                <Td isNumeric>
                  <IconButton
                    aria-label={`delete api key ${key.name}`}
                    size={"sm"}
                    icon={<DeleteIcon />}
                    variant={"ghost"}
                    onClick={() => setKeyToRevoke(key)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
      <Button
        mt={4}
        fontWeight={"normal"}
        size="sm"
        leftIcon={<AddIcon />}
        colorScheme="blue"
        onClick={creationDisclosure.onOpen}
      >
        Create new API key
      </Button>
    </Container>
  );
}
