import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import invariant from "tiny-invariant";
import { clientApi } from "./http";

export interface Org {
  organizationId: string;
  name: string;
  description: string;
  avatar: string;
  createdAt: number;
  role: string;
  verifyedName?: string;
}

export interface User {
  userId: string;
  name: string;
  email: string;
  avatar?: string;
  organizations: Org[];
}

let session = {
  loadUserCounter: 0,
};

function fetchUserFromSession(): User | null {
  let sessionData = sessionStorage.getItem("user-data");
  if (sessionData) {
    try {
      let jsonData = JSON.parse(sessionData);
      if (jsonData && jsonData.name && jsonData.email) {
        return jsonData as User;
      }
    } catch (e) {
      // TODO
    }
  }
  return null;
}

async function fetchUser(): Promise<User | null> {
  if (session.loadUserCounter == 0) {
    sessionStorage.removeItem("user-data");
  }
  session.loadUserCounter += 1;
  let user = fetchUserFromSession();
  if (user) {
    return user;
  }
  try {
    const res = await clientApi.get<{ user: User }>(
      "/users/profile?organizations=all"
    );
    if (res.status === 200) {
      return res.data.user;
    }
  } catch {
    // TODO
  }
  return null;
}

export function forceReloadUser() {
  session.loadUserCounter = 0;
  return fetchUser();
}

export function useOptionalUser() {
  const [user, setUser] = useState<User | null | undefined>(undefined);
  const location = useLocation();
  useEffect(() => {
    fetchUser().then((data) => {
      if (data) {
        sessionStorage.setItem("user-data", JSON.stringify(data));
        setUser(data);
      } else {
        setUser(null);
      }
    });
  }, [location]);
  return user;
}

export function mustGetUser() {
  let user = fetchUserFromSession();
  invariant(user);
  return user;
}

export function useUserUpdater() {
  const navigate = useNavigate();
  const location = useLocation();
  const updater = (profile: Partial<User>) => {
    let user = mustGetUser();
    sessionStorage.setItem(
      "user-data",
      JSON.stringify({
        ...user,
        ...profile,
      })
    );
    navigate(location.pathname + location.search);
  };
  return updater;
}

export function useRequireLogin() {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useOptionalUser();
  useEffect(() => {
    if (user === undefined) {
      return;
    } else if (user === null) {
      let query = "";
      let path = location.pathname;
      if (path && path.length > 1) {
        let params = new URLSearchParams();
        params.set("next", path);
        query = "?" + params.toString();
      }
      navigate("/signin" + query);
    }
  }, [user, location]);

  return user;
}
