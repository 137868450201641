import {
  Box,
  Button,
  Flex,
  Heading,
  InputRightElement,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Form, useNavigate } from "react-router-dom";
import { z } from "zod";
import FormInput from "~/ui/Form/FormInput";
import { useCountDown, useSuccessToast } from "~/utils";
import { clientApi } from "~/utils/http";

const schema = z
  .object({
    email: z
      .string()
      .trim()
      .min(1, { message: "Please input your email" })
      .email({ message: "Invalid email format" }),
    code: z.string().min(1, { message: "Please input verification code" }),
    password: z
      .string()
      .min(1, { message: "Please input your password" })
      .min(6, { message: "Length of password should be at least 6" })
      .max(32, "Length of password should not exceed 32"),
    passwordConfirm: z
      .string()
      .min(1, { message: "Please input your confirm password" }),
  })
  .refine(({ password, passwordConfirm }) => password === passwordConfirm, {
    path: ["passwordConfirm"],
    message: "Passwords must match",
  });

export default function PasswordReset() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setFocus,
    getValues,
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [countDown, resetCountDown] = useCountDown(0);
  const toast = useSuccessToast();
  const handleSendCode = async () => {
    const email = getValues("email");
    let res = z
      .string()
      .trim()
      .min(1, "Please input your email address")
      .email("Invalid email format")
      .safeParse(email);
    if (!res.success) {
      setError("email", {
        type: "validate",
        message: res.error.errors[0]?.message,
      });
      return;
    }
    resetCountDown(60);
    await clientApi.post("/users/code", {
      email: email,
      reason: "reset-password",
    });
    toast("Verification code was sent", `Please check your email ${email}`);
  };
  return (
    <Flex bg={useColorModeValue("gray.50", "gray.800")}>
      <Helmet>
        <title>Sath | reset password</title>
      </Helmet>
      <Stack spacing={8} mx={"auto"} width="440px" maxW={"lg"} py={20} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>Reset your password</Heading>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Form
            autoComplete="off"
            method="post"
            onSubmit={handleSubmit(async (data) => {
              try {
                await clientApi.post("/users/reset-password", {
                  email: data.email,
                  password: data.password,
                  code: data.code,
                });
                toast("Your password has been reset");
                navigate("/signin");
              } catch (e) {
                if (axios.isAxiosError(e)) {
                  setError("root", {
                    type: e.response?.status + "",
                    message: e.response?.data["message"],
                  });
                } else {
                  setError("root", {
                    type: "unkown",
                    message: "unknown error",
                  });
                }
              }
            })}
          >
            <Stack spacing={4}>
              <FormInput
                label="Email address"
                {...register("email")}
                error={errors.email?.message}
              />
              <FormInput
                label="Verification code"
                {...register("code")}
                error={errors.code?.message}
              >
                <InputRightElement w={20}>
                  <Button
                    isLoading={countDown > 0}
                    spinner={<Box>{countDown}</Box>}
                    onClick={handleSendCode}
                    colorScheme="teal"
                    size="sm"
                    w={16}
                  >
                    Send
                  </Button>
                </InputRightElement>
              </FormInput>
              <FormInput
                label="New password"
                type={showPassword ? "text" : "password"}
                {...register("password")}
                error={errors.password?.message}
              />
              <FormInput
                {...register("passwordConfirm")}
                label="Confirm Password"
                type="password"
                error={errors.passwordConfirm?.message}
              />
              <Stack>
                <Stack
                  direction={{ base: "column", sm: "row" }}
                  align={"start"}
                  justify={"space-between"}
                ></Stack>
                <Button mt={10} type="submit" colorScheme="teal">
                  Reset password
                </Button>
              </Stack>
              {errors.root && (
                <Text mt={4} color="red">
                  {errors.root.message}
                </Text>
              )}
            </Stack>
          </Form>
        </Box>
      </Stack>
    </Flex>
  );
}
