import axios from "axios";
import { UseFormSetError, FieldValues, Path } from "react-hook-form";

const getBaseUrl = () => {
  if (process.env.REACT_APP_URL) {
    return process.env.REACT_APP_URL;
  }
  let url;
  switch (process.env.NODE_ENV) {
    case "production":
      url = "https://api.sath.run";
      break;
    case "development":
    default:
      url = "http://localhost:8080";
  }

  return url;
};

const clientApi = axios.create({
  baseURL: getBaseUrl() + "/client/v1",
  withCredentials: true,
});

const devApi = axios.create({
  baseURL: getBaseUrl() + "/dev/v1",
  withCredentials: true,
});

const mockApi = axios.create({
  baseURL: "http://127.0.0.1:4523/m1/1597455-0-d3f135fd",
  withCredentials: true,
});

interface Error<T> {
  field: `root.${string}` | "root" | Path<T>;
  message: string;
  type?: string;
}

const handleError = <TFieldValues extends FieldValues>(
  e: unknown,
  setError: UseFormSetError<TFieldValues>
) => {
  let errors: Error<TFieldValues>[] = [];
  if (
    axios.isAxiosError<{
      errors: Error<TFieldValues>[];
    }>(e)
  ) {
    errors = e.response?.data.errors || [];
  }
  if (errors.length > 0) {
    for (let error of errors) {
      setError(error.field, {
        type: error.type,
        message: error.message,
      });
    }
  } else {
    setError("root", {
      type: "unkown",
      message: "unknown error",
    });
  }
};

export { clientApi, devApi, mockApi, handleError };
