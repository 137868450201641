import { Grid } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import Footer from "~/ui/Layout/Footer";
import Header from "~/ui/Layout/Header";

import { useOptionalUser } from "~/utils";

// { children }: PropsWithChildren
export default function Layout() {
  const user = useOptionalUser();
  return (
    <Grid templateRows="65px 1fr 73px" minH="100vh">
      <Header user={user} maxWidth={1200} />
      <Outlet />
      <Footer />
    </Grid>
  );
}
