import {
  Box,
  Button,
  Container,
  Divider,
  HStack,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { useForm } from "react-hook-form";
import { Form, useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import FormInput from "~/ui/Form/FormInput";
import { mustGetUser, useSuccessToast, useUserUpdater } from "~/utils";
import { clientApi, mockApi } from "~/utils/http";

function AvatarEditorModal({
  image,
  onClose,
}: {
  image: File | null;
  onClose: () => void;
}) {
  let [zoom, setZoom] = useState(1.0);
  let editor = useRef<AvatarEditor>(null);
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    setError,
  } = useForm();
  const updator = useUserUpdater();
  const toast = useSuccessToast();
  return (
    <Modal isOpen={!!image} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent
        as={Form}
        onSubmit={handleSubmit(async () => {
          if (!editor.current) {
            return;
          }
          let avatar = editor.current
            .getImageScaledToCanvas()
            .toDataURL("image/png");
          let res = await clientApi.patch<{ updated: { avatar: string } }>(
            "/users/profile",
            {
              avatar: avatar,
            }
          );
          updator({ avatar: res.data.updated.avatar });
          toast("Profile picture updated");
          onClose();
        })}
      >
        <ModalHeader fontSize={"md"}>Edit your image</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <AvatarEditor
            style={{ margin: "auto" }}
            ref={editor}
            image={image || ""}
            width={256}
            height={256}
            border={50}
            scale={zoom}
          />
          <Box px={8} mt={2}>
            <Slider
              aria-label="slider-ex-1"
              min={1}
              max={5}
              step={0.1}
              value={zoom}
              onChange={setZoom}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb bg={"teal"} />
            </Slider>
            <HStack
              fontSize={"sm"}
              color={"gray.500"}
              justifyContent={"space-between"}
            >
              <Text>1x</Text>
              <Text>ZOOM</Text>
              <Text>5x</Text>
            </HStack>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button size="sm" variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" size="sm" colorScheme="blue">
            Upload
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function ProfileUpdateForm() {
  const user = mustGetUser();
  const schema = z.object({
    name: z
      .string()
      .trim()
      .min(1, { message: "Please input your name" })
      .min(2, { message: "Name should contain at least 2 characters" }),
  });
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError,
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });
  const uploadRef = useRef<HTMLInputElement>(null);
  const updater = useUserUpdater();
  let [image, setImage] = useState<File | null>(null);
  const toast = useSuccessToast();
  return (
    <HStack alignItems={"start"} justifyContent={"space-between"}>
      <Box
        as={Form}
        maxW={"xl"}
        onSubmit={handleSubmit(async (data) => {
          await clientApi.patch("/users/profile", {
            name: data.name,
          });
          updater({ name: data.name });
          toast("Profile updated");
        })}
      >
        <FormInput
          {...register("name")}
          label="Name"
          defaultValue={user.name}
          error={errors.name?.message}
        />
        <Text fontSize={"sm"} color={"gray"} mt={2}>
          Your name may appear around Sath where you contribute or are
          mentioned. You can remove it at any time.
        </Text>
        <Box mt={4} textAlign={"right"}>
          <Button
            size="sm"
            type="submit"
            isLoading={isSubmitting}
            colorScheme={"blue"}
          >
            Update profile
          </Button>
        </Box>
      </Box>
      <Box w={"150px"}>
        <Text fontWeight={"semibold"} color={"gray.600"}>
          Profile picture
        </Text>
        <Image
          src={user.avatar}
          fallbackSrc="https://via.placeholder.com/256"
          h={"150px"}
          onClick={() => {
            uploadRef.current?.click();
          }}
          cursor={"pointer"}
        />
        <Input
          accept="image/*"
          ref={uploadRef}
          type="file"
          name="avatar"
          display={"none"}
          onChange={(event) => {
            let file = event.target.files?.[0];
            if (file) {
              setImage(file);
            }
          }}
        />
        <AvatarEditorModal image={image} onClose={() => setImage(null)} />
      </Box>
    </HStack>
  );
}

function PasswordUpdateForm() {
  const schema = z
    .object({
      oldPassword: z
        .string()
        .min(1, { message: "Please input your old password" }),
      newPassword: z
        .string()
        .min(1, { message: "Please input your new password" })
        .min(6, { message: "Length of password should be at least 6" })
        .max(32, "Length of password should not exceed 32"),
      passwordConfirm: z
        .string()
        .min(1, { message: "Please input your confirm password" }),
    })
    .refine(
      ({ newPassword, passwordConfirm }) => newPassword === passwordConfirm,
      {
        path: ["passwordConfirm"],
        message: "Passwords must match",
      }
    );
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    reset,
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });
  const toast = useSuccessToast();
  return (
    <Box maxW={"lg"}>
      <Heading size="md">Update password</Heading>
      <Divider my={4} />
      <Form
        onSubmit={handleSubmit(async (data) => {
          await mockApi.patch("/users/password", {
            oldPassword: data.oldPassword,
            newPassword: data.newPassword,
          });
          toast("Password updated");
          reset();
        })}
      >
        <VStack alignItems={"start"} color={"gray.600"} spacing={5}>
          <FormInput
            label="Old password"
            {...register("oldPassword")}
            borderColor={"gray.500"}
            error={errors.oldPassword?.message}
          />
          <FormInput
            label="New password"
            {...register("newPassword")}
            borderColor={"gray.500"}
            error={errors.newPassword?.message}
          />
          <FormInput
            label="Confirm new password"
            {...register("passwordConfirm")}
            borderColor={"gray.500"}
            error={errors.passwordConfirm?.message}
          />
          <Button
            type="submit"
            isLoading={isSubmitting}
            mt={4}
            size="sm"
            colorScheme="blue"
          >
            Update password
          </Button>
        </VStack>
      </Form>
    </Box>
  );
}

export default function UserSettings() {
  const user = mustGetUser();
  return (
    <Container my={4} maxW={"4xl"}>
      <Tabs variant="soft-rounded" colorScheme="green">
        <TabList>
          <Tab>Public profile</Tab>
          <Tab>Password</Tab>
        </TabList>
        <Divider my={4} />
        <TabPanels>
          <TabPanel>
            <ProfileUpdateForm />
          </TabPanel>
          <TabPanel>
            <PasswordUpdateForm />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
}
