import { AddIcon } from "@chakra-ui/icons";
import {
  Button,
  Container,
  Divider,
  Heading,
  Text,
  Link as RemixLink,
  Box,
  Spinner,
  VStack,
  Table,
  Thead,
  Th,
  Tr,
  Tbody,
  Td,
  RadioGroup,
  Radio,
  HStack,
  IconButton,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { devApi } from "~/utils/http";
import { FiRefreshCcw, FiPlay, FiPause } from "react-icons/fi";
import { FaPause, FaPlay } from "react-icons/fa";
import React from "react";

interface Project {
  projectId: string;
  name: string;
  description: string;
  status: string;
}

function ActionAlertDialog({
  isOpen,
  onClose,
  title,
  body,
  onConfirm,
  confirm,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => undefined | null | void | Promise<void>;
  title: string;
  body: string;
  confirm: string;
}) {
  const cancelRef = React.useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => {
    if (!isLoading) {
      onClose();
    }
  };
  const handleConfirm = async () => {
    if (isLoading) {
      return;
    }
    if (onConfirm) {
      setIsLoading(true);
      await onConfirm();
      setIsLoading(false);
    }
  };
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={handleClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{body}</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={handleClose} isLoading={isLoading}>
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              onClick={handleConfirm}
              ml={3}
              isLoading={isLoading}
            >
              {confirm}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default function Projects() {
  const [projects, setProjects] = useState<null | Project[]>(null);
  const [selected, setSeleted] = useState(0);
  const [reloads, setReloads] = useState(0);
  const [projectToStart, setProjectToStart] = useState<null | Project>(null);
  const [projectToPause, setProjectToPause] = useState<null | Project>(null);
  const params = useParams();
  const { orgId } = params;
  useEffect(() => {
    async function loadData() {
      let url = "/projects";
      if (orgId) {
        url += `?organizationId=${orgId}`;
      }
      let res = await devApi.get<{ projects: Project[] }>(url);
      setProjects(res.data.projects);
    }
    loadData();
  }, [reloads]);

  if (projects === null) {
    return (
      <Box textAlign={"center"} mt={8}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Box>
    );
  }

  if (projects.length === 0) {
    return (
      <Container mt={8} maxW={"lg"}>
        <Heading>About projects</Heading>
        <Divider my={2} />
        <Text>
          Projects is an adaptable, flexible tool for planning and tracking work
          on Sath. To create a project, please follow the API or SDK in our{" "}
          <RemixLink as={Link} to="/tutorial" color={"blue.200"}>
            tutorial
          </RemixLink>
          .
        </Text>
      </Container>
    );
  }

  return (
    <Container maxW={"4xl"}>
      <HStack
        mt={4}
        spacing={4}
        alignItems={"flex-end"}
        justifyContent={"space-between"}
        px="2"
      >
        <Heading size="lg">Projects</Heading>
        <IconButton
          size="md"
          color={"teal.400"}
          aria-label="Reload projects"
          icon={<FiRefreshCcw />}
          onClick={() => {
            setProjects(null);
            setReloads(reloads + 1);
          }}
          variant="ghost"
          isLoading={projects === null}
        />
      </HStack>
      {projectToStart && (
        <ActionAlertDialog
          isOpen={true}
          onClose={() => setProjectToStart(null)}
          onConfirm={async () => {
            await devApi.post("/projects/start", {
              projectId: projectToStart.projectId,
            });
            setProjectToStart(null);
          }}
          confirm="Start"
          title="Start Project"
          body={`Are you sure to start project ${projectToStart.name}`}
        />
      )}

      {projects.length > 0 && (
        <Table my={4} size={"sm"} variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Description</Th>
              <Th>Status</Th>
              <Th isNumeric>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {projects.map((project, i) => (
              <Tr key={project.projectId}>
                <Td>{project.name}</Td>
                <Td>{project.description}</Td>
                <Td>{project.status}</Td>
                <Td w="12" isNumeric>
                  {project.status === "running" ? (
                    <IconButton
                      size="xs"
                      color={"red.400"}
                      aria-label="Pause project"
                      icon={<FaPause />}
                      onClick={() => setProjectToPause(project)}
                    />
                  ) : project.status === "paused" ? (
                    <IconButton
                      size="xs"
                      color={"green.400"}
                      aria-label="Start project"
                      icon={<FaPlay />}
                      onClick={() => setProjectToStart(project)}
                    />
                  ) : null}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Container>
  );
}
