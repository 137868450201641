/* eslint-disable indent */
import {
  Box,
  BoxProps,
  Drawer,
  DrawerContent,
  Flex,
  FlexProps,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactNode, useEffect } from "react";
import { IconType } from "react-icons";
import { FiMenu } from "react-icons/fi";
import {
  GoDatabase,
  GoDeviceDesktop,
  GoGear,
  GoKey,
  GoOrganization,
  GoPeople,
  GoProject,
} from "react-icons/go";
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { NavLink, Outlet } from "react-router-dom";
import Header from "~/ui/Layout/Header";
import { isValidId, useOptionalUser, useRequireLogin } from "~/utils";
import ErrorPage from "~/routes/error-page";
import { Link } from "react-router-dom";

export default function Layout() {
  const navigate = useNavigate();
  const user = useRequireLogin();
  const params = useParams();

  if (!user) {
    return null;
  }

  const { orgId } = params;

  if (orgId) {
    if (!isValidId(orgId)) {
      return <ErrorPage />;
    }
    if (user.organizations.every((org) => org.organizationId !== orgId)) {
      return <ErrorPage />;
    }
  }

  return (
    <Grid templateRows="73px 1fr" templateColumns="240px 1fr" h="100vh">
      <GridItem colSpan={2}>
        <Header user={user} px={4} />
      </GridItem>
      <GridItem>
        <SimpleSidebar />
      </GridItem>
      <GridItem maxH={"calc(100vh) - 73px"} overflowY={"scroll"}>
        <Outlet />
      </GridItem>
    </Grid>
  );
}

interface LinkItemProps {
  name: string;
  icon: IconType;
  link: string;
  isActive?: boolean;
}

function SimpleSidebar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        bg={useColorModeValue("gray.50", "gray.900")}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {/* Content */}
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const params = useParams();
  const { orgId } = params;
  const linkItems: Array<LinkItemProps> = orgId
    ? [
        { name: "Members", icon: GoPeople, link: `/${orgId}` },
        { name: "Projects", icon: GoProject, link: `/${orgId}/projects` },
        { name: "Datasets", icon: GoDatabase, link: `/${orgId}/datasets` },
        { name: "Settings", icon: GoGear, link: `/${orgId}/settings` },
      ]
    : [
        { name: "Organizations", icon: GoOrganization, link: "/" },
        { name: "API Keys", icon: GoKey, link: "/api-keys" },
        { name: "Projects", icon: GoProject, link: "/projects" },
        { name: "Datasets", icon: GoDatabase, link: "/datasets" },
        { name: "Settings", icon: GoGear, link: "/settings" },
      ];
  const location = useLocation();
  let activeIndex = linkItems.findIndex((item, i) => {
    let match = matchPath({ path: item.link, end: i === 0 }, location.pathname);
    return match !== null;
  });
  if (activeIndex === -1) {
    activeIndex = 0;
  }
  return (
    <Box
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      {linkItems.map((link, i) => (
        <NavItem
          key={link.name}
          icon={link.icon}
          to={link.link}
          isActive={activeIndex === i}
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  to: string;
  isActive: boolean;
  children: ReactNode;
}
const NavItem = ({ icon, children, to, isActive, ...rest }: NavItemProps) => {
  return (
    <Box
      as={Link}
      to={to}
      bg={isActive ? "blue.900" : undefined}
      display={"block"}
      _focus={{ boxShadow: "none" }}
    >
      <Flex
        align="center"
        p="3"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "cyan.400",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Box>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text fontSize="2xl" ml="8" fontFamily="monospace" fontWeight="bold">
        Logo
      </Text>
    </Flex>
  );
};
